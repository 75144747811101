import type { ReactNode } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { useState } from 'react';
import * as styles from './navigation-link.module.scss';

interface Props {
  to: string;
  className?: string;
  children: ReactNode | string;
  onClick?: () => void;
}

export default function NavigationLink({
  to, children, className = '', onClick,
}: Props) {
  const [ hover, setHover ] = useState(false);
  const onHover = (hoverValue: boolean) => {
    setHover(hoverValue);
  };

  const linkClasses = classNames(
    styles.navigationLink,
    { [styles.hover]: hover },
    className,
  );
  return (
    <Link to={ to }
          activeClassName={ styles.active }
          className={ linkClasses }
          onMouseOver={ () => onHover(true) }
          onMouseLeave={ () => onHover(false) }
          onClick={ () => onClick?.() }>
      { children }
    </Link>
  );
}
