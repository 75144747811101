import type { MutableRefObject, ReactNode } from 'react';
import { useEffect, useState } from 'react';

export function useLayoutScroll(ref: MutableRefObject<HTMLElement>, children: ReactNode) {
  const element = ref.current;

  const [ prevChildren, setPrevChildren ] = useState(children);
  if (prevChildren !== children) {
    setPrevChildren(children);
    element?.scrollTo(0, 0);
  }

  const [ isScrolled, setIsScrolled ] = useState(false);

  useEffect(() => {
    const scrollCallback = () => setIsScrolled(element.scrollTop > 50);
    element?.addEventListener('scroll', scrollCallback);

    return () => element?.removeEventListener('scroll', scrollCallback);
  }, [ element ]);

  const scrollToTop = () => element?.scrollTo({ top: 0, behavior: 'smooth' });

  return { scrollToTop, isScrolled };
}
