// @ts-ignore -- z jakiegoś powodu edytor tutaj zaznacza na czerwono
import { SocialIcon } from 'react-social-icons/component';
import 'react-social-icons/instagram';
import 'react-social-icons/facebook';
import 'react-social-icons/mailto';

interface Props {
  className?: string;
  color?: string;
  iconClassName?: string;
}

export default function SocialIcons({ className, color, iconClassName }: Props) {
  const defaultColor = 'rgb(55, 65, 81)';

  return (
    <section className={ className }>
      <SocialIcon url="https://www.instagram.com/simpleflower.pl/"
                  bgColor="transparent"
                  fgColor={ color || defaultColor }
                  className={ iconClassName }
                  target="_blank"
                  rel="noopener noreferrer" />
      <SocialIcon url="https://www.facebook.com/profile.php?id=100089118500304"
                  bgColor="transparent"
                  fgColor={ color || defaultColor }
                  className={ iconClassName }
                  target="_blank"
                  rel="noopener noreferrer" />
      <SocialIcon url="mailto:kontakt@simpleflower.pl"
                  bgColor="transparent"
                  fgColor={ color || defaultColor }
                  className={ iconClassName }
                  target="_blank"
                  rel="noopener noreferrer" />
    </section>
  );
}
