import 'animate.css';
import './src/assets/styles/global.scss';
import * as React from 'react';
import type { GatsbyBrowser } from 'gatsby';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReactDOM from 'react-dom/client';
import Layout from './src/components/layout/Layout';

export const replaceHydrateFunction = () => (element, container) => {
  const root = ReactDOM.createRoot(container);
  root.render(element);
};

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={ queryClient }>
      { element }
    </QueryClientProvider>
  );
};

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => (
  <Layout { ...props }>
    { element }
  </Layout>
);
