import type { ReactNode } from 'react';
import { useRef } from 'react';
import { ChevronUpIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import Header from './header/Header';
import Footer from './Footer';
import * as styles from './Layout.module.scss';
import { WideLayoutContext } from './hooks/context';
import { useWideLayout } from './hooks/use-wide-layout';
import { useLayoutScroll } from './hooks/use-layout-scroll';

export function DefaultHead(title?: string, description?: string) {
  const metaTitle = title || 'Simple Flower - usługi florystyczne i dekoratorskie';
  const metaDescription = description || 'Simple Flower to minimalistyczny koncept florystyczny zajmujący się dekoracją ślubów i wesel oraz imprez okolicznościowych. Nasze usługi florystyczne i dekoratorskie świadczymy na terenie Iławy i okolic.';
  const metaUrl = 'https://simpleflower.pl/';

  return (
    <>
      <html lang="pl" />
      <meta charSet="utf-8" />
      <title>{ metaTitle }</title>
      <meta name="description" content={ metaDescription } />
      <meta name="keywords"
            content="instagram, inspiracje, iława, ilawa, impreza okolicznościowa, iłąwa, florystyka, pracownia florystyczna, bukiety kwiatów, florysta, dekoracje na wesele, bukiet ślubny, bukiety ślubne, dekoracje wesele, dekoracje wesela, dekoracje wesel, portfolio, ślub, dekorator ślubny, dekoracje ślubne, flowerbox, wesela, imprezy, bukety, ozdoby, bukietu kwiatów, bukiet kwiatów" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="canonical" href={ metaUrl } />

      <meta property="og:title" content={ metaTitle } />
      <meta property="og:description" content={ metaDescription } />
      { /* <meta property="og:image" content="https://your-website.com/og-image.png" /> */ }
      <meta property="og:url" content={ metaUrl } />
    </>
  );
}

interface Props {
  children: ReactNode;
}

export default function Layout({ children }: Props) {
  const mainRef = useRef(null);
  const { isScrolled, scrollToTop } = useLayoutScroll(mainRef, children);
  const wideLayout = useWideLayout(children);

  const backToTopClasses = classNames(
    styles.backToTop,
    'animate__animated',
    'animate__faster',
    'animate__bounceIn',
    { hidden: !isScrolled },
  );

  const containerClasses = classNames(
    styles.container,
    { [styles.wide]: wideLayout.isWide },
  );

  return (
    <main className={ styles.main }
          ref={ mainRef }>
      <Header />
      <section className={ containerClasses }>
        <WideLayoutContext.Provider value={ wideLayout }>
          { children }
        </WideLayoutContext.Provider>
      </section>
      { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */ }
      <div className={ backToTopClasses } onClick={ scrollToTop }>
        <ChevronUpIcon />
      </div>
      <Footer />
    </main>
  );
}
