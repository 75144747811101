import { useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { Link } from 'gatsby';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { StaticImage } from 'gatsby-plugin-image';
import classNames from 'classnames';
import * as styles from './Header.module.scss';
import { MenuLinks } from './MenuLinks';

export default function Header() {
  const [ mobileMenuOpen, setMobileMenuOpen ] = useState(false);
  const ref = useDetectClickOutside({ onTriggered: () => setMobileMenuOpen(false) });

  const onMenuClick = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const mobileMenuClasses = classNames(
    styles.mobileMenu,
    // Change here if you want to add more menu items
    { 'max-h-0': !mobileMenuOpen, 'max-h-68': mobileMenuOpen },
  );

  return (
    <nav className={ styles.headerContainer } ref={ ref }>
      <Link to="/"
            className={ styles.logoLink }>
        <StaticImage src="../../../assets/images/logo.png"
                     width={ 140 }
                     placeholder="none"
                     quality={ 100 }
                     alt="logo" />
      </Link>
      <div className={ styles.navigationContainer }>
        <div className={ styles.navigation }>
          <MenuLinks />
        </div>
      </div>
      <div className={ styles.mobileNavigationContainer }>
        <button aria-label="menu"
                type="button"
                onClick={ onMenuClick }
                className={ styles.mobileNavigationButton }>
          <Bars3Icon className="h-8 w-8" />
        </button>
      </div>
      <div className={ mobileMenuClasses }>
        <div className={ styles.mobileMenuWrapper }>
          <MenuLinks onClick={ onMenuClick } />
        </div>
      </div>
    </nav>
  );
}
