import NavigationLink from './navigation-link/navigation-link';

interface Props {
  onClick?: () => void;
}

export function MenuLinks({ onClick }: Props) {
  return (
    <>
      <NavigationLink to="/" onClick={ onClick }>Strona główna</NavigationLink>
      <NavigationLink to="/o-nas/" onClick={ onClick }>O nas</NavigationLink>
      <NavigationLink to="/oferta/" onClick={ onClick }>Oferta</NavigationLink>
      <NavigationLink to="/portfolio/" onClick={ onClick }>Portfolio</NavigationLink>
      <NavigationLink to="/kontakt/" onClick={ onClick }>Kontakt</NavigationLink>
    </>
  );
}
