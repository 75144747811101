import type { ReactNode } from 'react';
import { useState } from 'react';

export function useWideLayout(children: ReactNode) {
  const [ isWide, setIsWide ] = useState(true);

  const [ prevChildren, setPrevChildren ] = useState(children);
  if (prevChildren !== children) {
    setPrevChildren(children);
    setIsWide(true);
  }

  return { isWide, setIsWide };
}
