import { Link } from 'gatsby';
import classNames from 'classnames';
import SocialIcons from '../common/SocialIcons';

export default function Footer() {
  const footerClasses = classNames(
    'w-full',
    'md:pb-8',
    'pb-16',
  );

  return (
    <footer className={ footerClasses }>
      <div className="md:max-w-[80%] w-full mx-auto flex md:flex-row flex-col p-7 opacity-50">
        <section className="flex-1 hidden md:block" />
        <section className="flex-1 text-xs mt-8 text-center">
          Copyright &copy; { new Date().getFullYear() }, Simple&nbsp;Flower
          <br />
          <SocialIcons iconClassName="!w-8 !h-8" />
          <Link to="/polityka-prywatnosci/" className="font-semibold">Polityka prywatności</Link>
        </section>
        <section className="flex-1 text-xs mt-8 mb-16 md:mb-0 md:text-right text-center">
          Projekt i wykonanie <a href="https://klos.dev" className="font-semibold" target="_blank" rel="noopener noreferrer">Radosław&nbsp;Kłos</a>
        </section>
      </div>
    </footer>
  );
}
